// AddProfileModal.js
import React from 'react';
import { Modal, Form, Input, Select, Row, Col } from 'antd';

const AddProfileModal = ({ isVisible, onClose }) => {
  return (
        <Modal
            title="Aggiungi Profilo ADS Power"
            visible={isVisible}
            onCancel={onClose}
            className="modal-style"
            width={800} // Imposta la larghezza desiderata
            // ...altre prop necessarie per il modal...
        >
            <Form layout="vertical">
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Nome">
                        <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Cognome">
                        <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Email">
                        <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Password">
                        <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Facebook login">
                        <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Facebook password">
                        <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Facebook 2FA Code">
                        <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="ID_ADS_POWER">
                        <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="ID Business Manager">
                        <Select>
                            {/* Opzioni ottenute da un endpoint remoto */}
                        </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {/* Pulsanti del form */}
            </Form>
        </Modal>
  );
};

export default AddProfileModal;
