import React, { useState } from 'react';
import { Layout, Card, Menu, Button } from 'antd';
import { HomeOutlined, LoginOutlined, UserAddOutlined } from '@ant-design/icons';

//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//Tabella principale
import AutomationsTable from './components/AutomationsTable';

//Modals
import TrackingModal from './components/TrackingModal';
import AddProfileModal from './components/AddProfileModal';
import LoginModal from './components/LoginModal';

// Importa il tuo CSS personalizzato
import './App.css';
import './HomePage.css';

const { Header, Footer, Content } = Layout;

/*
function App() {
  return (
    <div className="App">
      <AutomationsTable />
    </div>
  );
}

export default App;
*/

function App() {

  const [selectedAutomationId, setSelectedAutomationId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Stati per gestire la visibilità dei modali
  const [isAddProfileVisible, setIsAddProfileVisible] = useState(false);
  const [isLoginVisible, setIsLoginVisible] = useState(false);

  // Funzioni per mostrare i modali
  const showAddProfileModal = () => setIsAddProfileVisible(true);
  const showLoginModal = () => setIsLoginVisible(true);

  const handleRowClick = (automationId) => {
    console.log("Riga cliccata, ID Automazione:", automationId);
    setSelectedAutomationId(automationId);
    setIsModalVisible(true);  // Apri il modal quando una riga è cliccata
  };

  /*
  return (
    <div>
      <AutomationsTable onRowClick={handleRowClick} />
      {selectedAutomationId && (
        <TrackingModal
          automationId={selectedAutomationId}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </div>
  );
  */

  return (
    <Layout className="layout">

      <Header className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
          <Menu.Item key="1" icon={<HomeOutlined />}>Profile list</Menu.Item>
          <Menu.Item key="2">BM Change requests</Menu.Item>
          <Menu.Item key="3">AD Account Topups</Menu.Item>
        </Menu>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button icon={<UserAddOutlined />} onClick={showAddProfileModal}>
            Aggiungi Profilo ADSP
          </Button>
          <Button icon={<LoginOutlined />} onClick={showLoginModal} style={{ marginLeft: '10px' }}>
            Login
          </Button>
          {/* Altri pulsanti o icone */}
        </div>
      </Header>

      <AddProfileModal
        isVisible={isAddProfileVisible}
        onClose={() => setIsAddProfileVisible(false)}
      />
      <LoginModal
        isVisible={isLoginVisible}
        onClose={() => setIsLoginVisible(false)}
      />

      <Content className="content" style={{ padding: '50px' }}>
        <Card className="site-layout-content">
          <AutomationsTable onRowClick={handleRowClick} />
          {selectedAutomationId && (
            <TrackingModal
              automationId={selectedAutomationId}
              visible={isModalVisible}
              onClose={() => setIsModalVisible(false)}
            />
          )}
        </Card>
      </Content>

      <Footer className="footer" style={{ textAlign: 'center', padding: '5px 0' }}>
        <p>© {new Date().getFullYear()} Whitelist Pro. Tutti i diritti riservati.</p>
      </Footer>

    </Layout>
  );

}

export default App;