// In TrackingModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Table, Spin, Badge } from 'antd';

import '../css/CustomStyle.css'; // Importa il file CSS

import moment from 'moment'; // Importa moment
import axios from 'axios';

const statusMap = {
    NEW: 'processing',
    DONE: 'success',
    ERROR: 'error',
    PENDING: 'warning',
};

const TrackingModal = ({ automationId, visible, onClose }) => {

    const [trackingData, setTrackingData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Aggiunto stato di caricamento
  
    useEffect(() => {

      setIsLoading(true); // Inizia il caricamento
      axios.get(`https://wipro.musty.ovh/api/automations/${automationId}/tracking`)

        .then(response => {

            //console.log(response.data);  // Log per vedere la struttura dei dati
            //setTrackingData(response.data);

            /*
                chiave: status

                0: NEW
                1: DONE
                2: ERROR
                3: PENDING
            */

            const formattedData = response.data.map(item => {
                // Mappa lo status da numero a testo
                let statusText = '';
                switch (item.status) {
                    case 0:
                        statusText = 'NEW';
                        break;
                    case 1:
                        statusText = 'DONE';
                        break;
                    case 2:
                        statusText = 'ERROR';
                        break;
                    case 3:
                        statusText = 'PENDING';
                        break;
                    default:
                        statusText = 'UNKNOWN'; // O qualsiasi altro valore di default che preferisci
                }
    
                return { 
                    ...item, 
                    created_at: moment(item.created_at).format('DD/MM/YYYY HH:mm'),
                    status: statusText  // Sostituisci lo status numerico con il testo corrispondente
                };
            });

            setTrackingData(formattedData);
            setIsLoading(false); // Fine del caricamento

        })
        .catch(error => {

            console.error('Errore nel recupero dei dati:', error);
            setTrackingData([]);
            setIsLoading(false); // Fine del caricamento anche in caso di errore

        });

    }, [automationId]);
  
    const columns = [
        {
            title: 'Tracking ID',
            dataIndex: 'id_ads_power_automation_tracking',
            key: 'id_ads_power_automation_tracking',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => <Badge status={statusMap[status]} text={status} />,
        }
    ];

    return (
        <Modal
            title="Dettagli Tracking"
            visible={visible}
            onOk={onClose}
            onCancel={onClose}
            className="modal-style"
            width={800} // Imposta la larghezza desiderata
        >
            {isLoading ? (
                <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} />
            ) : (
                <Table dataSource={trackingData} columns={columns} scroll={{ y: 480 }} />
            )}
        </Modal>
    );

    /*
    return (
        <div className="modal">
          <table>
            <thead>
            </thead>
            <tbody>

                {trackingData && trackingData.map(tracking => (
                
                    <tr key={tracking.id_ads_power_automation_tracking}>
                        <td>{tracking.id_ads_power_automation_tracking}</td>
                        <td>{tracking.description}</td>
                        <td>{tracking.created_at}</td>
                        <td>{tracking.status}</td>
                    </tr>

                ))}

            </tbody>
          </table>
          <button onClick={onClose}>Chiudi</button>
        </div>
    );
    */

};

export default TrackingModal;
