// LoginModal.js
import React from 'react';
import { Modal, Form, Input } from 'antd';

const LoginModal = ({ isVisible, onClose }) => {
  return (
    <Modal
      title="Login"
      visible={isVisible}
      onCancel={onClose}
      // ... altre prop ...
    >
        <Form layout="vertical">
            <Form.Item label="Nome Utente">
            <Input />
            </Form.Item>
            <Form.Item label="Password">
            <Input.Password />
            </Form.Item>
            {/* Pulsanti del form */}
        </Form>
    </Modal>
  );
};

export default LoginModal;
