// In AutomationsTable.js
import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Space, Badge } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import '../css/CustomStyle.css'; // Importa il file CSS

import moment from 'moment'; // Importa moment
import axios from 'axios';

const statusMap = {
    'Fallita': 'error',
    'Completa': 'success',
    'In corso': 'warning',
};

const AutomationsTable = ({ onRowClick }) => {

  const [automations, setAutomations] = useState([]);
  const [selectedAutomationId, setSelectedAutomationId] = useState(null);

  useEffect(() => {
    axios.get('https://wipro.musty.ovh/api/automations')
      .then(response => {
        //setAutomations(response.data);

        const formattedData = response.data.map(item => {

            // Parsing di operational_data
            let operationalData = {};
            try {
                operationalData = JSON.parse(item.operational_data);
            } catch (error) {
                console.error('Errore nel parsing di operational_data:', error);
            }

            /*

                0: Fallita
                1: Completa
                2: In corso

            */
            // Mappa lo status da numero a testo
            let statusText = '';
            switch (item.status) {
                case 0:
                    statusText = 'Fallita';
                    break;
                case 1:
                    statusText = 'Completa';
                    break;
                case 2:
                    statusText = 'In corso';
                    break;
                default:
                    statusText = 'UNKNOWN'; // O qualsiasi altro valore di default che preferisci
            }

            return { 
                ...item, 
                created_at: moment(item.created_at).format('DD/MM/YYYY HH:mm'),
                status: statusText,  // Sostituisci lo status numerico con il testo corrispondente
                fb_ad_account_id: item.fb_ad_account_id ? item.fb_ad_account.id : null,
                fb_business_manager_id: item.fb_business_manager_id ? item.fb_business_manager.id : null,
                fb_bm_share_id: operationalData.fb_bm_share_id // Accedi a fb_bm_share_id
            };
        });
        setAutomations(formattedData);

      })
      .catch(error => {
        console.error('Errore nel recupero delle automazioni:', error);
      });
  }, []);

  // Funzione per gestire il click su una riga
  /*
  const handleRowClick = (automationId) => {

    // Implementazione del click qui
    setSelectedAutomationId(automationId);

  };
  */

  const columns = [
    {
        title: 'ID',
        dataIndex: 'id_ads_power_automation',
        key: 'id_ads_power_automation',
    },
    {
        title: 'Riga Google Sheet',
        dataIndex: 'row',
        key: 'row',
    },
    {
        title: 'FB Ad Account ID',
        dataIndex: 'fb_ad_account_id',
        key: 'fb_ad_account_id',
    },
    {
        title: 'FB Business Manager ID',
        dataIndex: 'fb_business_manager_id',
        key: 'fb_business_manager_id',
    },
    {
        title: 'FB Profile Account ID',
        dataIndex: 'fb_profile_account_id',
        key: 'fb_profile_account_id',
    },
    {
        title: 'Partner',
        dataIndex: 'fb_bm_share_id',
        key: 'fb_bm_share_id',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => <Badge status={statusMap[status]} text={status} />,
    },
    {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
    }
  ];

  return (
    <div className="table-style">
        <Table
        dataSource={automations}
        columns={columns}
        onRow={(record) => ({
            onClick: () => onRowClick(record.id_ads_power_automation) // Assicurati che 'id_ads_power_automation' sia la chiave corretta
        })}
        />
    </div>
  );

  /*
  return (
    <table>
      <thead>
      </thead>
      <tbody>
        {automations.map(automation => (
            <tr key={automation.id_ads_power_automation} onClick={() => handleRowClick(automation.id_ads_power_automation)}>
                <td>{automation.id_ads_power_automation}</td>
                <td>{automation.fb_ad_account_id}</td>
                <td>{automation.fb_business_manager_id}</td>
                <td>{automation.fb_profile_account_id}</td>
                <td>{automation.status}</td>
                <td>{automation.created_at}</td>
            </tr>
        ))}
        {selectedAutomationId && <TrackingModal automationId={selectedAutomationId} onClose={() => setSelectedAutomationId(null)} />}
      </tbody>
    </table>
  );
  */
  
};

export default AutomationsTable;
